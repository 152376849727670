




import LabSimpleDistillation from '../simulations/LabSimpleDistillation.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabTask from './labs/LabTask.vue';

export default defineComponent({
  name: 'SimpleDistillationSIM',
  components: {LabSimpleDistillation, LabTask},
  mixins: [DynamicQuestionMixin()],
});
