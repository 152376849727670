









import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export enum ESimpleDistillationVariations {
  SceneTwo = 0,
  NoSceneTwo = 1,
}

export default defineComponent({
  name: 'LabSimpleDistillation',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    useSceneTwo: {
      type: Number as PropType<ESimpleDistillationVariations>,
      default: ESimpleDistillationVariations.SceneTwo,
    },
  },
});
